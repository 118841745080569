import config from "../../config/config"
import { handleResponse } from "../../utils/handleResponse";
import { checkResponse } from "../../utils/checkResponse";

export const bookingService = { merchantSettings, getTimeslots, reserve }

function merchantSettings() {
    return (
        fetch(`${config.baseUrl}/cli/booking/settings?merchantId=${config.MERCHANT_ID}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function getTimeslots(weekDay) {
    return (
        fetch(`${config.baseUrl}/cli/booking/settings/availableSlots?merchantId=${config.MERCHANT_ID}&weekDay=${weekDay}`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
            mode: "cors",
            credentials: "include"
        })
            .then(handleResponse)
            .then(checkResponse)
    );
}

function reserve(data) {
    return (
        fetch(`${config.baseUrl}/cli/booking/add`, {
            method: 'POST',
            headers: { "Content-Type": "application/json", },
            mode: "cors",
            credentials: 'include',
            body: JSON.stringify({
                merchantId: config.MERCHANT_ID,
                bookingType: data.bookingType,
                personnelId: data.personnelId,
                activities: [data.service],
                date: data.date,
                hour: data.timeslot,
                note: data.notes,
                userPhoneNum: data.mobilePhone,
                userEmail: data.email,
                userFirstName: data.firstName,
                userLastName: data.lastName,
                personsNum: data.personsNum,
            })
        })
            .then(handleResponse)
            .then(checkResponse)
    )
}