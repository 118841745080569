import React from 'react';
import { ReactComponent as SuccessIcon } from '../../../images/success-booking.svg';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

import "./success-popup.styles.scss";

function SuccessPopup({ show, setShow, reserveInfo }) {

    const { t } = useTranslation();

    const handleClose = () => setShow(true);

    let date, personnelName, bookingType, dayOfWeek, dayNumber, month, time, activity, personsNum;
    if (reserveInfo) {
        bookingType = reserveInfo.bookingType
        personnelName = reserveInfo.personnelName;
        date = new Date(reserveInfo.date);
        dayOfWeek = date.toLocaleDateString("it-IT", { weekday: 'long' })
        dayNumber = date.toLocaleDateString("it-IT", { day: 'numeric' })
        month = date.toLocaleDateString("it-IT", { month: 'long' })
        time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        activity = reserveInfo.activities && reserveInfo.activities[0] && reserveInfo.activities[0].description;
        personsNum = reserveInfo.personsNum;
    }

    return (
        <Modal
            dialogClassName="success-popup"
            centered={true}
            show={show}
            onHide={handleClose}
        >
            <SuccessIcon />
            <div className="success-popup-title">
                {t("booking.success.title")}
            </div>
            {reserveInfo && bookingType === "NO_FOOD" &&
                <div className="success-popup-text">
                    Ti confermiamo che la richiesta di appuntamento di 
                    <b> {dayOfWeek} {dayNumber} {month} </b> alle ore <b>{time}</b> per <b>{activity} </b>
                    <b>{personnelName && `con ${personnelName}`}</b> è stata inviata.
                </div>
            }
            {reserveInfo && bookingType === "FOOD" &&
                <div className="success-popup-text">
                    Ti confermiamo che la richiesta di prenotazione tavolo di 
                    <b> {dayOfWeek} {dayNumber} {month} </b> alle ore <b>{time}</b>
                    per <b>{personsNum} </b> persone è stata inviata.
                </div>
            }
            <div className="success-popup-text">
                {t("booking.success.email")}
            </div>
            <div className="action">
                <Link to="/">
                    <button className="cta" onClick={handleClose}>
                        <span>{t("booking.success.home")}</span>
                    </button>
                </Link>
            </div>
        </Modal>
    );
}

export default SuccessPopup;